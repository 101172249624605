.video-js-player-default {
  .video-js {
    /* Rectangle 148 */

    .vjs-control-bar {
      @apply flex visible opacity-100 transition-[visibility,opacity] h-10 duration-100 bg-transparent;

      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.6) 100%
      );

      .vjs-play-control {
        order: 1;

        .vjs-icon-placeholder:before {
          @apply text-xl leading-10;
        }
      }

      .vjs-time-control {
        order: 2;
        @apply text-xs leading-10;
      }

      .vjs-progress-control {
        order: 3;
        @apply h-10 py-2 px-6 gap-6 !important;

        .vjs-load-progress * {
          @apply bg-white rounded-sm;
        }

        .vjs-play-progress {
          @apply bg-breaking-background opacity-100 rounded-sm;
          &:before {
            @apply hidden;
          }
        }

        .vjs-progress-holder {
          @apply h-0.5 bg-white;
        }
      }
    }
  }
}

.with-timer-controls {
  .vjs-time-divider,
  .vjs-current-time,
  .vjs-duration {
    @apply p-0 block text-white text-sm font-semibold font-notosans;
  }
  .vjs-time-divider {
    @apply flex items-center justify-center;
  }
}

.vjs-user-inactive .vjs-control-bar {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

/* Show controls when user interacts */
.vjs-user-active .vjs-control-bar {
  opacity: 1;
}
